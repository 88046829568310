import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { fadeIn } from 'libs/animations';
import IconAngleDown from 'images/icons/AngleDown';
import BlankButton from 'components/forms/BlankButton';
import LazyImage from 'components/LazyImage';

//#region

const SingularImage = styled.div`
	border-radius: ${p => p.theme.utils.borderRadius};
	padding: ${p => (p.layout === 'default' && '0') || '30px'};
	.lazy-image {
		min-height: 150px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	max-height: 100%;
	animation-name: ${fadeIn};
	animation-duration: 500ms;
	animation-delay: 500ms;
	animation-fill-mode: forwards;
	opacity: 0;
	padding-bottom: 40px;
	${p =>
		p.theme.media.large(css`
			padding-bottom: 60px;
		`)}

	.slick-slide {
		height: 100%;
		> div {
			height: 100%;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			flex-direction: column;
			justify-content: center;
		}
		&.slick-active {
			z-index: 99999999;
		}
	}
	.slick-track {
		display: flex;
		flex-wrap: wrap;
		height: 100%;
		max-height: calc(100vh - 40px);
	}

	.slick-slider {
		width: 100%;
		display: flex;
		align-items: center;
		.slick-list {
			height: 100%;
			width: 100%;
		}
		img {
			border-radius: ${p => p.theme.utils.borderRadius};
		}
		.slick-arrow {
			border-radius: 0;
			&::before {
				display: none;
			}
			&:focus {
				outline: 1px solid ${p => p.theme.colors.grey700};
				outline-offset: 3px;
			}
		}
	}

	// Position arrows in the corner
	${p =>
		p.arrowsposition === 'corner' &&
		css`
			.slick-slider {
				.slick-arrow {
					transform: none;
					top: calc(100% - 50px);
					margin-top: 0;
					border-radius: 100%;
					width: 35px;
					height: 35px;
					background-color: ${p => p.theme.colors.white};
					box-shadow: ${p => p.theme.utils.boxShadow};
					transition: opacity 0.3s;
					svg {
						vertical-align: text-bottom !important;
					}
					&:hover {
						opacity: 0.8;
					}
					&:focus {
						outline: 1px dotted ${p => p.theme.colors.black} !important;
						outline-offset: 0px !important;
					}
				}
				.slick-prev {
					left: auto;
					right: 60px;
				}
				.slick-next {
					right: 15px;
				}
			}
		`}

	.slick-dots {
		bottom: -35px;
		${p =>
			p.theme.media.large(css`
				bottom: -45px;
			`)}
	}

	.icon-color-white {
		.slick-dots li button:before {
			color: white;
		}
	}
`;

const Slide = styled.div`
	position: relative;
	cursor: pointer;
	text-align: center;
	display: flex !important;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	img {
		border-radius: ${p => p.theme.utils.borderRadius};
	}
	> div {
		max-height: calc(100vh - 150px);
	}
	.lazy-image {
		min-height: 150px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

const CaptionWrapper = styled.div`
	text-align: center;
	margin: auto;
	font-size: 16px;
	line-height: 24px;
	font-style: italic;
	flex: 1;
	display: block;
	max-width: 800px;
	width: 100%;
	margin-top: 15px;
	color: ${p =>
		(p.textcolor && p.theme.colors[p.textcolor]) || p.theme.colors.grey900};
	animation-name: ${fadeIn};
	animation-duration: 500ms;
	animation-delay: 2s;
	animation-fill-mode: forwards;
	opacity: 0;
`;

const ImageCaption = styled.span``;

const TotalAndIndex = styled.span``;

const ThumbSlider = styled(Slider)`
	align-items: flex-start;
	margin: 15px 0 0;
	${p =>
		p.theme.media.large(css`
			margin: 20px 0 0;
		`)}
	${p =>
		p.layout === 'default' &&
		css`
			max-width: 500px;
			margin: 20px auto !important;
		`}
	.slick-track {
		flex-wrap: nowrap;
	}
	.slick-slide {
		height: auto;
		padding: 5px;

		${p =>
			p.theme.media.mediumDown(css`
				${p =>
					p.slideCount <= 4 &&
					css`
						width: auto !important;
					`}
			`)}
		${p =>
			p.theme.media.large(css`
				${p =>
					p.slideCount <= 3 &&
					css`
						width: auto !important;
					`}
			`)}
		> div {
			height: 100%;
		}
	}

	${Slide} {
		height: 100%;
		max-height: 75px;
		${p =>
			p.theme.media.mediumOnly(css`
				max-height: 100px;
			`)}
		${p =>
			p.theme.media.large(css`
				max-height: 130px;
			`)}
		> div,
		> div > div {
			height: 100%;
			width: 100%;
		}
	}
`;

const ThumbnailImg = styled(LazyImage)`
	border-radius: ${p => p.theme.utils.borderRadius};
`;

const ArrowIcon = styled(BlankButton)`
	margin-top: -20px;
	z-index: 999999;

	svg {
		color: ${p => p.theme.colors.black};
		width: 20px;
		height: 20px;
		transform: ${p =>
			p.direction === 'prev' ? 'rotate(90deg)' : 'rotate(-90deg)'};
	}
	&.slick-prev {
		@media (min-width: 1310px) {
			left: -30px;
		}
	}
	&.slick-next {
		@media (min-width: 1310px) {
			right: -30px;
		}
	}

	&.slick-disabled {
		opacity: 0.3;
		pointer-events: none;
		cursor: not-allowed;
	}

	${p =>
		p.iconcolors === 'white' &&
		css`
			background-color: ${() => transparentize(0.4, 'white')} !important;
			width: 35px !important;
			height: 35px !important;
			border-radius: 35px !important;
			display: flex !important;
			align-items: center;
			justify-content: center;
			box-shadow: ${p => p.theme.utils.boxShadow};
			svg {
				color: ${p => p.theme.colors.black} !important;
			}
			&:hover {
				background-color: white !important;
			}
			&:focus {
				outline: none !important;
				background-color: white !important;
			}
			&.slick-prev {
				left: 10px !important;
			}
			&.slick-next {
				right: 10px !important;
			}

			${p =>
				p.theme.media.smallOnly(css`
					width: 30px !important;
					height: 30px !important;
					border-radius: 30px !important;
					svg {
						width: 17px;
						height: 17px;
					}
					&.slick-prev {
						left: 5px !important;
					}
					&.slick-next {
						right: 5px !important;
					}
				`)}
			svg {
				color: ${p => p.theme.colors.white};
			}
			&.slick-prev {
				left: -5px;
				@media (min-width: 1310px) {
					left: 10px;
				}
			}
			&.slick-next {
				right: -5px;
				@media (min-width: 1310px) {
					right: 10px;
				}
			}
		`}
`;
//#endregion

export default function SlickSlider({
	layout = 'default',
	iconcolors = 'black',
	images,
	current = {},
	dots = false,
	arrows = false,
	fade = false,
	centerMode = false,
	autoplay = false,
	infinite = true,
	showcaption = 'false',
	thumbnails = { show: false, count: 3 },
	...props
}) {
	const sliderRef = useRef();
	const [mainSlider, setMainSlider] = useState();
	const [thumbnailSlider, setThumbnailSlider] = useState();
	const [currentIndex, setCurrentIndex] = useState(current?.index || 0);

	const handleKeyDown = e => {
		if (e.key === 'ArrowLeft') {
			sliderRef.current.slickPrev();
		} else if (e.key === 'ArrowRight') {
			sliderRef.current.slickNext();
		}
	};

	useEffect(() => {
		if (!sliderRef?.current) return;

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [sliderRef]);

	const settings = {
		dots,
		arrows,
		centerMode,
		infinite,
		fade,
		autoplay,
		speed: 750,
		autoplaySpeed: 4000,
		slidesToShow: 1,
		slidesToScroll: 1,
		initialSlide: current?.index || 0,
		nextArrow: <Arrow direction="next" iconcolors={iconcolors} />,
		prevArrow: <Arrow direction="prev" iconcolors={iconcolors} />,
		beforeChange: (_, newIndex) => {
			setCurrentIndex(newIndex);
		},
	};

	if (!images.length) return <></>;

	if (images?.length === 1) {
		return (
			<SingularImage layout={layout} className="singular-image">
				<LazyImage
					{...images[0]}
					caption={null}
					fit={props?.fit || 'none'}
					alt={images[0]?.alt || images[0]?.description}
					load={props?.load}
					ratio={props?.ratio}
				/>

				<Caption
					show={showcaption === 'true'}
					text={images[0]?.imagetext}
					textcolor={iconcolors}
					total={images?.length}
					index={currentIndex}
				/>
			</SingularImage>
		);
	}

	return (
		<>
			<Wrapper
				role="region"
				arrowsposition={props?.arrowsposition || 'outside'}
				aria-label={`${props['aria-label']}. Viser slide ${
					currentIndex + 1
				} av ${images?.length}.`}
				className="multiple-images">
				<Slider
					{...settings}
					ref={sliderRef}
					className={`slider icon-color-${iconcolors}`}
					{...(thumbnails?.show && {
						asNavFor: thumbnailSlider,
						ref: slider1 => setMainSlider(slider1),
					})}>
					{images.map((image, i) => {
						return (
							<Slide key={i} layout={layout} className="slide">
								<LazyImage
									{...image}
									caption={null}
									fit={props?.fit || 'none'}
									alt={
										image?.alt ||
										image?.caption ||
										image?.description
									}
									ratio={props?.ratio}
									load={props?.load}
								/>

								<Caption
									show={showcaption === 'true'}
									text={image?.imagetext}
									textcolor={iconcolors}
									total={images?.length}
									index={currentIndex}
								/>
							</Slide>
						);
					})}
				</Slider>

				{thumbnails?.show && (
					<ThumbSlider
						layout={layout}
						asNavFor={mainSlider}
						ref={slider2 => setThumbnailSlider(slider2)}
						slideCount={images?.length}
						swipeToSlide={true}
						focusOnSelect={true}
						role="region"
						arrows={false}
						dots={false}
						aria-label={props['aria-label']}
						speed={1000}
						slidesToShow={
							images?.length < thumbnails?.count
								? images?.length
								: thumbnails?.count
						}
						responsive={[
							{
								breakpoint: 1100,
								settings: {
									slidesToShow:
										images?.length < 4 ? images?.length : 4,
								},
							},
						]}>
						{images.map((image, i) => {
							if (!image?.thumbnail?.file?.url) return <></>;

							return (
								<Slide key={i}>
									<ThumbnailImg
										{...image?.thumbnail}
										width={100}
										height={100}
										alt={image?.alt}
										layout={layout}
									/>
								</Slide>
							);
						})}
					</ThumbSlider>
				)}
			</Wrapper>
		</>
	);
}

function Arrow(props) {
	const { iconcolors, className, style, onClick, direction = 'next' } = props;
	return (
		<ArrowIcon
			className={className}
			direction={direction}
			style={style}
			iconcolors={iconcolors}
			onClick={onClick}
			title={`Gå til ${
				(direction === 'next' && 'neste') || 'forrige'
			} bilde`}>
			<IconAngleDown size="xs" />
		</ArrowIcon>
	);
}

function Caption({ show = true, text, textcolor, total, index }) {
	if (!show) return null;

	return (
		<CaptionWrapper textcolor={textcolor}>
			{text && <ImageCaption>{text}</ImageCaption>}
			<TotalAndIndex>
				{(index !== -1 &&
					total > 1 &&
					text &&
					` (Bilde ${index + 1}/${total})`) ||
					`Bilde ${index + 1}/${total}`}
			</TotalAndIndex>
		</CaptionWrapper>
	);
}
